import type { ComponentInstance } from 'vue';

export type GlobalHelpers = {
    generateLocalePath: (input: string | undefined, skipLocalPath?: boolean) => string | undefined;
};

export default defineNuxtPlugin((nuxtApp) => {
    const localePath = useLocalePathWoom();
    const route = useRoute();

    const helpers: GlobalHelpers = {
        generateLocalePath: (input: string | undefined, skipLocalPath?: boolean) => {
            if (!input) return;

            // don't generate a locale path if what we got was an absolute link [usually an external link]
            if (input.startsWith('http')) {
                return input;
            }
            if (input.startsWith('#')) {
                const queryEntries = Object.entries(route.query);
                const queryParams = queryEntries.length ? `?${queryEntries.map(([key, value]) => `${key}=${value}`).join('&')}` : '';
                return `${route.path}${queryParams}${input}`;
            }

            if (skipLocalPath) return input;

            const output = localePath(input);

            const inputFirstSegment = input.split('/').find((x) => x.length > 0);

            const outputFirstSegment = output.split('/').find((x) => x.length > 0);

            // if generated url has the same starting segment as our output, the locale path was already applied
            // so just return the input unchanged
            if (inputFirstSegment === outputFirstSegment) {
                if (import.meta.env.DEV && import.meta.client) {
                    const currentInstance = getCurrentInstance();
                    let parentPart = '';
                    const addParentPath = (parent: ComponentInstance<any> | null) => {
                        const componentName = parent?.type?.__name || parent?.type?.name;
                        const componentFile = parent?.type?.__file;

                        parentPart += `\nfrom component '${componentName}'`;
                        if (componentFile) parentPart += ` (${componentFile})`;
                        if (parent?.parent) addParentPath(parent?.parent);
                    };

                    addParentPath(currentInstance?.parent);

                    // eslint-disable-next-line no-console
                    console.warn(
                        `%c generateLocalePath just stopped you from applying locale path to a link twice${parentPart}`,
                        'background: #222; color: #bada55',
                        input,
                    );
                }
                return input;
            }

            // otherwise, return the new url with locale applied
            return output;
        },
    };

    nuxtApp.provide('helpers', helpers);

    // so we can use these as template helpers
    nuxtApp.vueApp.use({
        install() {
            // @ts-ignore
            nuxtApp.vueApp.helpers = helpers;
        },
    });
});
